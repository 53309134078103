<template>
  <v-sheet
    tabindex="0"
    title="haz clic para cargar el archivo"
    color="white"
    width="100%"
    :height="height"
    :class="{
      'pa-2 v-card--link drop-zone c-border elevation-0': true,
      active: dragging,
      'c-error': error,
    }"
    elevation="2"
    @dragover.prevent="dragging = true"
    @dragleave.prevent="dragging = false"
    @drop.prevent="onChange"
    @click="!file ? filesSelected() : removeFile()"
  >
    <v-overlay :absolute="true" :value="true"> </v-overlay>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <p class="text-center" v-if="!file || file == ''">
            <v-btn fab text large color="blue">
              <v-icon dark x-large> mdi-tray-arrow-up </v-icon>
            </v-btn>
            <br />
            <span>Arrastrar tus archivos aquí o bien</span>
            <br />
            <br />
            <v-btn color="primary" outlined>Buscar archivo</v-btn>
          </p>
          <p v-else class="text-center">
            <v-btn fab text large color="success">
              <v-icon dark> mdi-check </v-icon>
            </v-btn>
            <br />
            <span>{{ file.name }}</span> <br />
            <span>{{ file.size / 1000 }} KB</span> <br />
            <v-icon color="success darken-1"> mdi-image </v-icon>
          </p>
        </v-col>
      </v-row>
    </v-container>
    <input
      @change="onChange"
      ref="filedrop"
      type="file"
      :accept="accept.join(',')"
      style="display: none"
    />
  </v-sheet>
</template>

<script>
export default {
  name: "FileDrop",
  props: {
    accept: {
      default: () => ["*"],
    },
    extensionText: {
      type: String,
      default: "",
    },
    file: [String, File],
    height: {
      type: Number,
      default: 400,
    },
    error: {
      type: Boolean,
      default: false,
    },
    base64: {
      type: Boolean,
      defaul: false,
    },
  },
  data: () => ({
    // file: "",
    maxSize: 5000000,
    dragging: false,
  }),
  computed: {
    messages() {
      return this.$store.getters.GetText.messages;
    },
  },
  methods: {
    filesSelected() {
      var fileDrop = this.$refs.filedrop;
      fileDrop.click();
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },
    async createFile(file) {
      if (
        !file.type.match(this.accept[0]) &&
        !file.type.match(this.accept[1])
      ) {
        // INGRESE AQUI EL CODIGO PARA MOSTRAR UN MENSAJE DE ERROR DE EXTENSION
        this.$store.commit("setSnackbar", {
          active: true,
          text: "Error formato de imagen no válido.",
          top: true,
          right: true,
          color: "error",
        });
        this.dragging = false;
        return;
      }

      //   if (file.size > this.maxSize) {
      //     alert('please check file size no over 5 MB.')
      //     this.dragging = false;
      //     return;
      //   }

      // this.file = file;
      const base64 = await this.toBase64(file);
      this.$emit("file-selected", file, this.base64 ? base64 : null);
      this.dragging = false;
    },
    removeFile() {
      this.$emit("remove-file");
      // this.file = "";
      let fileDrop = this.$refs.filedrop;
      fileDrop.value = "";
    },
  },
};
</script>

<style lang="scss">
.drop-zone {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  .v-overlay__scrim {
    background-color: rgba(206, 206, 206, 0) !important;
  }
  &.active {
    background-color: rgba(206, 206, 206) !important;
  }
}
</style>
